import { createNavigation } from 'next-intl/navigation'
import { defineRouting } from 'next-intl/routing'
import { getRequestConfig } from 'next-intl/server'
import { z } from 'zod'

import { getTranslations } from '@/services/common'

export const routing = defineRouting({
  locales: ['en', 'th', 'ko', 'jp', 'ch', 'tw', 'ru'],
  localeDetection: true,
  defaultLocale: 'en'
})

export const locales = [
  { key: routing.locales[0], locale: 'en-US', label: 'English (US)' },
  { key: routing.locales[1], locale: 'th-TH', label: 'ภาษาไทย' },
  { key: routing.locales[2], locale: 'ko-KR', label: '한국어' },
  { key: routing.locales[3], locale: 'ja-JP', label: '日本語' },
  { key: routing.locales[4], locale: 'zh-CN', label: '中文（简）' },
  { key: routing.locales[5], locale: 'zh-TW', label: '中文（繁）' },
  { key: routing.locales[6], locale: 'ru-RU', label: 'русский язык' }
]

export const { Link, redirect, usePathname, useRouter } = createNavigation(routing)

export const localeQueryValidator = z.object({ locale: z.enum([...routing.locales]) })

export default getRequestConfig(async ({ requestLocale }) => {
  // Read from potential `[locale]` segment.
  let locale = await requestLocale

  // Ensure that the incoming locale is valid
  if (!locale || !routing.locales.includes(locale as any)) {
    locale = routing.defaultLocale
  }

  const data = await getTranslations()
  const messages = data[locale]

  // Manual assign
  Object.assign(messages['Menu'], {
    PRIVACY_AND_POLICY: 'PRIVACY & POLICY'
  })

  return {
    locale,
    messages
  }
})
