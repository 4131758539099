'use client'

import { useCallback, useMemo } from 'react'

import { useAppStore } from '@/libs/stores/app.store'

export function useLoader() {
  // __STATE's
  const setLoader = useAppStore((action) => action.setLoader)

  // __FUNCTION's
  const handleAction = useCallback((active: boolean = true, delay: number = 240) => {
    if (active) setLoader(active)
    else {
      setTimeout(() => {
        setLoader(active)
      }, delay)
    }
  }, [])

  // __RETURN
  return useMemo(
    () => ({
      on: handleAction,
      off: (delay?: number) => handleAction(false, delay)
    }),
    []
  )
}
