import { z } from 'zod'

export const envSchema = z.object({
  APP_MODE: z
    .union([z.literal('production'), z.literal('development'), z.literal('local')])
    .default('local'),
  APP_NAME: z.string().default('project_name'),
  APP_BASE_URL: z.string().default('http://www.example.com'),
  API_GATEWAY: z.string().default('https://api.example.com'),
  API_SECRET_KEY: z.string().default('AP1-S3C23T-K3Y'),
  TZ: z.string().default('Asia/Bangkok'),
  GTM_ID: z.string().optional(),
  BAIDU_ANALYTIC_ID: z.string().optional()
})

export const paramValidator = z.object({ id: z.any() })

export const queryValidator = z.object({
  page: z
    .string()
    .optional()
    .transform((value) => Number(value || 1)),
  limit: z
    .string()
    .optional()
    .transform((value) => Number(value || 10)),
  search: z.string().optional()
})

export type Environment = z.infer<typeof envSchema>
export type ParamDto = z.infer<typeof paramValidator>
export type QueryDto = z.infer<typeof queryValidator>
