type Locale = { key: string; locale: string; label: string }
type InputItem = Record<string, Record<string, string>>
type ResultItem = Record<string, InputItem>

export function transformAndGroup(locales: Locale[], input: InputItem[]) {
  const result: ResultItem = {}

  for (const { key } of locales) {
    // Initialize the top-level object for each locale
    result[key] = {}
  }

  for (const item of input) {
    for (const [key, translations] of Object.entries(item)) {
      const [group, subKey] = key.split('.')

      for (const r of locales) {
        if (!result[r.key][group]) {
          // Initialize the group if not exists
          result[r.key][group] = {}
        }

        result[r.key][group][subKey] =
          translations[r.key]?.replace(/(<([^>]+)>)/gi, '') || `"${key}" is not yet translated`
      }
    }
  }

  return result
}
