'use client'

import { addSeconds } from 'date-fns'
import { ReactNode, useCallback, useMemo } from 'react'

import type { Notice, NoticeOptions, NoticeTypes } from '@/components/addons'
import { useAppStore } from '@/libs/stores/app.store'
import { generateId } from '@/libs/utils'

export function useNotice() {
  // __STATE's
  const setNotice = useAppStore((action) => action.setNotice)

  // __FUNCTION's
  const handleAction = useCallback((option: Omit<Notice, 'vid' | 'visible'>) => {
    const vid = generateId()
    const title = option?.title || "Notification's"
    let duration = undefined

    if (option?.duration !== 0) {
      duration = +addSeconds(new Date(), option.duration || 3)
    }

    setNotice({
      ...option,
      vid,
      title,
      duration
    })

    return vid
  }, [])

  // __RETURN
  return useMemo(
    () => ({
      on: (children: ReactNode, type: NoticeTypes, option?: NoticeOptions) =>
        handleAction({ ...option, type, children }),

      info: (children: ReactNode, option?: NoticeOptions) =>
        handleAction({ ...option, type: 'info', children }),

      success: (children: ReactNode, option?: NoticeOptions) =>
        handleAction({ ...option, type: 'success', children }),

      warn: (children: ReactNode, option?: NoticeOptions) =>
        handleAction({ ...option, type: 'warn', children }),

      error: (children: ReactNode, option?: NoticeOptions) =>
        handleAction({ ...option, type: 'error', children }),

      close: (vid: string) => setNotice({ vid: `rm:${vid}` } as any),

      clear: () => setNotice(undefined)
    }),
    []
  )
}
