'use client'

import { addSeconds } from 'date-fns'
import { useCallback, useMemo } from 'react'

import type { ToastOptions } from '@/components/addons'
import { useAppStore } from '@/libs/stores/app.store'
import { generateId } from '@/libs/utils'

export function useToast() {
  // __STATE's
  const setToast = useAppStore((action) => action.setToast)

  // __FUNCTION's
  const handleOn = useCallback((message: string, options?: ToastOptions) => {
    const vid = options?.name || generateId()
    let duration = undefined

    if (options?.duration !== 0) {
      duration = +addSeconds(new Date(), options?.duration || 2)
    }

    setToast({
      vid,
      duration,
      children: message
    })

    return vid
  }, [])

  const handleOff = useCallback((vid: string) => {
    setToast({ vid: `rm:${vid}` } as any)
  }, [])

  // __RETURN
  return useMemo(
    () => ({
      on: handleOn,
      off: handleOff,
      clear: () => setToast(undefined)
    }),
    []
  )
}
