'use client'

import { ReactNode, useCallback, useMemo } from 'react'

import type {
  Dialog,
  DialogAlertOptions,
  DialogConfirmOptions,
  DialogModalOptions
} from '@/components/addons'
import { useAppStore } from '@/libs/stores/app.store'
import { generateId } from '@/libs/utils'

export function useDialog() {
  // __STATE's
  const setDialog = useAppStore((action) => action.setDialog)

  // __FUNCTION's
  const handleAlert = useCallback(async (children: ReactNode, options?: DialogAlertOptions) => {
    return new Promise((resolve, reject) => {
      const vid = generateId()
      const payload: Extract<Dialog, { type: 'alert' }> = {
        ...options,
        type: 'alert',
        vid,
        children,
        resolve
      }

      setDialog(payload)
    })
  }, [])

  const handleConfirm = useCallback(async (children: ReactNode, options?: DialogConfirmOptions) => {
    return new Promise((resolve, reject) => {
      const vid = generateId()
      const payload: Extract<Dialog, { type: 'confirm' }> = {
        ...options,
        type: 'confirm',
        vid,
        children,
        resolve
      }

      setDialog(payload)
    })
  }, [])

  const handleModel = useCallback((children: ReactNode, options?: DialogModalOptions) => {
    const vid = options?.name || generateId()
    const payload: Extract<Dialog, { type: 'modal' }> = {
      type: 'modal',
      name: vid,
      vid,
      children,
      ...options
    }

    setDialog(payload)
  }, [])

  // __RETURN
  return useMemo(
    () => ({
      alert: handleAlert,
      confirm: handleConfirm,
      modal: handleModel,
      off: (vid: string) => setDialog({ vid: `rm:${vid}` } as any)
    }),
    []
  )
}
