import { unstable_cache as cache } from 'next/cache'
import { groupBy, omit, prop } from 'ramda'

import { CONTENT_LANG, env } from '@/constants/configs'
import { locales } from '@/libs/intl'
import { transformAndGroup } from '@/libs/transform'

import { createHeaders } from './helpers'

export const getTranslations = cache(
  async () => {
    const headers = createHeaders()
    const response = await fetch(`${env.API_GATEWAY}/api/translation/`, { method: 'post', headers })
    const data = await response.json()

    return transformAndGroup(locales, data.data.translations)
  },
  ['translations'],
  {
    revalidate: 60,
    tags: ['translations']
  }
)

export const getCommon = (locale: string = 'en') =>
  cache(
    async () => {
      const headers = createHeaders([{ name: CONTENT_LANG, value: locale }])
      const response = await fetch(`${env.API_GATEWAY}/api/home/`, {
        method: 'post',
        headers
      })
      const { data } = await response.json()

      return omit(['csrf_token'], data)
    },
    ['common', locale],
    {
      revalidate: 60,
      tags: ['common']
    }
  )()

export const getBranches = (locale: string = 'en') =>
  cache(
    async () => {
      const headers = createHeaders([{ name: CONTENT_LANG, value: locale }])
      const response = await fetch(`${env.API_GATEWAY}/api/location/`, {
        method: 'post',
        headers
      })
      const { data } = await response.json()

      const sorted = groupBy(prop('city_code'), data) as Record<string, any[]>
      const result = Object.keys(sorted).map((key) => {
        const city = sorted[key][0]

        return {
          id: city['city_id'],
          code: city['city_code'],
          name: city['city'],
          branches: sorted[key]
        }
      }) as IBranch[]

      return result
    },
    ['branches', locale],
    {
      revalidate: 60,
      tags: ['branches']
    }
  )()

export const getBlogs = (locale: string = 'en') =>
  cache(
    async () => {
      const headers = createHeaders([{ name: CONTENT_LANG, value: locale }])
      const response = await fetch(`${env.API_GATEWAY}/api/tos/blog/`, {
        method: 'post',
        headers
      })
      const data = await response.json()

      return data.data
    },
    ['blogs'],
    {
      revalidate: 60,
      tags: ['blogs']
    }
  )()
