import { envSchema } from '@/libs/validator.zod'

export const env = envSchema.parse({
  APP_MODE: process.env.NEXT_PUBLIC_APP_MODE,
  APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
  APP_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  API_GATEWAY: process.env.NEXT_PUBLIC_API_GATEWAY,
  API_SECRET_KEY: process.env.NEXT_PUBLIC_API_SECRET_KEY,
  TZ: process.env.NEXT_PUBLIC_APP_TZ,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  BAIDU_ANALYTIC_ID: process.env.NEXT_PUBLIC_BAIDU_ANALYTICS_ID
})

export const isBrowser = typeof window !== 'undefined'
export const isProduction = env.APP_MODE === 'production'
export const isDevelop = env.APP_MODE === 'development'

// STORAGE KEY-NAME
export const APP_AUTH_ACCESS = `__APP.AccessToken${isDevelop && `-${env.APP_NAME}`}`
export const APP_AUTH_REFRESH = `__APP.RefreshKey${isDevelop && `-${env.APP_NAME}`}`
export const APP_LANG = `__APP.Language${isDevelop && `-${env.APP_NAME}`}`
export const APP_THEME = `__APP.Theme${isDevelop && `-${env.APP_NAME}`}`
export const APP_USER_INFO = `__APP.UserInfo${isDevelop && `-${env.APP_NAME}`}`

// REQUEST HEADERS
export const ACCEPT_LANG = 'Accept-Language'
export const ACCEPT_RANGES = 'Accept-Ranges'
export const CONTENT_LANG = 'Content-Language'
export const CONTENT_LENGTH = 'Content-Length'
export const CONTENT_RANGE = 'Content-Range'
export const CONTENT_TYPE = 'Content-Type'
