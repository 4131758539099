'use client'

import { Button } from '@nextui-org/button'
import { useCallback, useEffect } from 'react'

import { useLoader } from '@/hooks'
import { Link } from '@/libs/intl'

type Props = {
  error: Error & { digest?: string }
  reset: () => void
}

export default function ThrowError({ error, reset }: Props) {
  // __STATE's
  const loader = useLoader()

  // __FUNCTION's
  const handleReset = useCallback(() => {
    loader.on()
    reset()

    setTimeout(loader.off, 200)
  }, [reset])

  // __EFFECT's
  useEffect(() => {
    // Log the error to an error reporting service
    console.error('<ThrowError />', error)
  }, [error])

  // __RENDER
  return (
    <div className='container grid min-h-screen'>
      <div className='flex flex-col gap-1 place-self-center text-center'>
        <h1 className='mb-4 text-9xl font-extrabold'>Oops!</h1>
        <h3 className='text-3xl font-bold capitalize'>unexpected error</h3>
        <p className=''>An error occurred and you request couldn't be completed.</p>

        <div className='mt-8 flex items-center justify-center gap-4'>
          <Button className='font-semibold' color='default' radius='sm' onPress={handleReset}>
            Try again
          </Button>

          <Button className='font-semibold' as={Link} href='/' color='primary' radius='sm'>
            Go back home
          </Button>
        </div>
      </div>
    </div>
  )
}
