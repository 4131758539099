import { env } from '@/constants/configs'

export function createHeaders(values?: Record<'name' | 'value', string>[]) {
  const headers = new Headers()
  headers.set('Secretkey', env.API_SECRET_KEY)

  if (values?.length) {
    for (const record of values) {
      headers.set(record.name, record.value)
    }
  }

  return headers
}
