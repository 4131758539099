import { isBrowser } from './configs'

export { Fruit } from './fruit'

export const headers: HeadersInit = {
  'Access-Control-Allow-Origin': isBrowser ? location.origin : '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Content-Type, Content-Language, Authorization'
}

export enum Theme {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum Locales {
  US = 'en-US',
  TH = 'th-TH',
  KR = 'ko-KR',
  JP = 'ja-JP',
  CN = 'zh-CN',
  TW = 'zh-TW',
  RU = 'ru-RU'
}
