import { create } from 'zustand'

import type { Dialog, Notice, Toast } from '@/components/addons'
import { Theme } from '@/constants'

type States = {
  appVersion: string
  language: string
  loader: boolean
  theme: Theme
  dialogs: Dialog[]
  notices: Notice[]
  toasts: Toast[]
}

type Actions = {
  setLanguage: (language: string) => void
  setTheme: (theme: Theme) => void
  setLoader: (value: boolean) => void
  setDialog: (payload: Dialog) => void
  setNotice: (payload?: Notice) => void
  setToast: (payload?: Toast) => void
}

export const useAppStore = create<States & Actions>((setState, getState) => {
  const regex = /^rm:|remove:/g

  return {
    // STATE's
    appVersion: 'v0.2-beta (July, 2024)',
    language: 'en-US',
    theme: Theme.DARK,
    loader: false,
    dialogs: [],
    notices: [],
    toasts: [],

    // ACTION's
    setLanguage: (language) => setState({ language }),
    setTheme: (theme) => setState({ theme }),
    setLoader: (value) => setState({ loader: value }),

    setDialog: (payload) => {
      const { dialogs } = getState()

      if (dialogs.length) {
        const vid = payload.vid.replaceAll(regex, '').trim()
        let results = [...dialogs]

        if (regex.test(payload.vid)) {
          results = results.filter((r) => r.vid !== vid)
        } else {
          results.push(payload)
        }

        setState({ dialogs: results })
      } else {
        setState({ dialogs: [payload] })
      }
    },

    setNotice: (payload) => {
      const { notices } = getState()

      if (notices.length) {
        if (payload) {
          const vid = payload.vid.replaceAll(regex, '').trim()
          let results = [...notices]

          if (regex.test(payload.vid)) {
            results = results.filter((r) => r.vid !== vid)
          } else {
            results.push(payload)
          }

          setState({ notices: results })
        } else {
          setState({ notices: [] })
        }
      } else {
        setState({ notices: payload ? [payload] : [] })
      }
    },

    setToast: (payload) => {
      const { toasts } = getState()

      if (toasts.length) {
        if (payload) {
          const vid = payload.vid.replaceAll(regex, '').trim()
          let results = [...toasts]

          if (regex.test(payload.vid)) {
            results = results.filter((r) => r.vid !== vid)
          } else {
            results.unshift(payload)
          }

          setState({ toasts: results })
        } else {
          setState({ toasts: [] })
        }
      } else {
        setState({ toasts: payload ? [payload] : [] })
      }
    }
  }
})
